<template>
  <div>
    <MainMenuComponent />
    <div class="contact-us-page">
      <div class="container">
        <div class="row d-flex justify-content-center">
          <div class="col-12 col-md-6 pb-md-5">
            <form class="row" @submit.prevent="handleSubmit" style="overflow-x: hidden;">
              <div class="row">
                <div class="col-md-8 col-12 text-block">
                  <div class="form-item text-start">
                    <div>Имя</div>
                    <input
                      id="name"
                      v-model="name"
                      type="text"
                      class="form-input phone-input w-100"
                      required
                    >
                  </div>
                  <div class="form-item text-start mt-4">
                    <div>Телефон</div>
                    <input
                      id="phone"
                      v-model="phone"
                      type="tel"
                      class="form-input phone-input w-100"
                      required
                    >
                  </div>
                  <div class="form-item text-start mt-4">
                    <div>Сообщение</div>
                    <input
                      id="message"
                      v-model="message"
                      type="text"
                      class="form-input phone-input w-100"
                      required
                    >
                  </div>
                  <div class="form-item-btn mt-4 text-end">
                    <div class="text-start text-terms">Нажимая на кнопку, я выражаю 
                      <router-link style="color: #1fc4c2;" to="/personalData">согласие на обработку персональных данных</router-link>
                    </div>
                    <button
                      type="submit"
                      class="h-100 btn-style mt-4"
                    >
                      Отправить
                    </button>
                  </div>
                </div>
                
              </div>
            </form>
          </div>
          <div class="col-12 col-md-6 text-center mobile-mode">
            <h2>Задайте вопрос — дадим бесплатную консультацию</h2>
            <p class="text-block mt-4">+7(495)147-81-00</p>
            <p class="text-block">info@soh100.ru</p>
          </div>
        </div>
      </div>
    </div>
    <FooterComponent />
  </div>  
</template>

<script>
import MainMenuComponent from '@/components/MainMenuComponent.vue'
import FooterComponent from '@/components/FooterComponent.vue'

export default {
  name: 'ContactUsPage',
  components: {
    MainMenuComponent,
    FooterComponent
  },
  data () {
    return {
      name: '',
      phone: '',
      message: '',
      letterSent: false,
      agreementChecked: false,
      elasticMailUsername: this.elasticMailUsername,
      elasticMailKey: this.elasticMailKey
    }
  },
  methods: {
    handleSubmit () {
      this.letterSent = false
      /* eslint-disable no-undef */
      Email.send({
        Host: 'smtp.elasticemail.com',
        Username: this.elasticMailUsername,
        Password: this.elasticMailKey,
        To: this.elasticMailUsername,
        From: this.elasticMailUsername,
        Subject: 'Letter from the SOH100 website',
        Body: `
          <table style="width: 800px; font-size: 12pt; font-family: Arial, sans-serif, sans-serif;" cellspacing="0" cellpadding="0" border="0">
            <tbody>
              <tr>
                <td style="min-width: 100px;">
                  <p style="FONT-FAMILY: Arial, sans-serif; padding: 0px; font-size: 12pt; line-height: 14pt; margin-bottom: 0px;">
                    <span>
                      <span style="font-size: 12pt; color: #333333;"><strong>Name:</strong></span>
                      <span style="font-size: 12pt; color:#333333;"> ${this.name}</span>
                      <span><br></span>
                    </span>
                    <span>
                      <span style="font-size: 12pt; color: #333333;"><strong>Message:</strong></span>
                      <span style="font-size: 12pt; color:#333333;"> ${this.message}</span>
                      <span><br></span>
                    </span>
                    <span>
                      <span style="font-size: 12pt; color: #333333;"><strong>Phone:</strong></span>
                      <span style="font-size: 12pt; color:#333333;"> ${this.phone}</span>
                      <span><br></span>
                    </span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        `
      }).then((message) => {
        console.log(message)
        if (message === 'OK') {
          this.letterSent = true
          alert('Письмо отправлено')
        } else {  
          alert(message)
        }
      })
    }
  }
}
</script>

<style scoped>

.text-terms {
  font-size: 1.2rem;
}

.contact-us-page {
  margin-top: 8rem;
}

.text-block {
  font-size: 1.4rem;
  color: rgb(41, 41, 41);
}

.form-input {
  padding: 10px 15px;
  border-radius: 15px;
}

.btn-style {
  font-size: 1.4rem;
  padding: 10px 20px;
  border-radius: 15px;
}

@media (max-width: 768px) {
  .contact-us-page {
    margin-top: 3.5rem;
  }
  .mobile-mode {
    display: none;
  }
}

</style>
