<template>
  <div class="image-switcher">
    <img 
      :src="isHovered ? imageSrc : bImageSrc" 
      @mouseover="isHovered = true" 
      @mouseleave="isHovered = false"
      class="main-image" 
    />
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  props: {
    image_src: {
      type: String,
      default: '...'
    },
    b_image_src: {
      type: String,
      default: '...'
    },
  },
  setup(props) {
    const imageSrc = ref(props.image_src)
    const bImageSrc = ref(props.b_image_src)
    const isHovered = ref(false)

    return {
      imageSrc,
      bImageSrc,
      isHovered
    }
  }
}
</script>

<style scoped>
.main-image {
  max-width: 400px;
  max-height: 300px;
}

@media only screen and (max-width: 767px) {
  .main-image {
    max-width: 100px;
    max-height: 100px;
  }
}
</style>