<template>
  <div class="head-component pt-3">
    <div class="container-lg">
      <nav class="nav-menu navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">
          <a class="navbar-brand" href="#">
            <div class="d-flex align-items-center">
              <img class="logo-style me-2" src="images/logo.png" alt="..." />
              <div class="logo-text">SOH100</div>
            </div>
          </a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span><img class="mobile-icon" src='images/icons8-menu-50.png' alt='...' /></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0 w-100 justify-content-end">
              <li class="nav-item me-md-4 mt-4 mt-md-0">
                <router-link  class="menu-nav-link" aria-current="page" to="/store">Продукты</router-link >
              </li>
              <li class="nav-item me-md-4 mt-2 mt-md-0">
                <router-link class="menu-nav-link" aria-current="page" to="/partnership">Франшиза</router-link>
              </li>
              <li class="nav-item me-md-5 mt-2 mt-md-0">
                <router-link class="menu-nav-link" aria-current="page" to="/contact">Контакты</router-link>
              </li>
              <li class="nav-item mt-3 mt-md-0">
                <a class="login-nav-link" aria-current="page" href="http://my.soh100.ru/login">Войти</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div class="slogan row justify-content-center">
        <h2 class="company-title">
          Safety Of Health <span style="color: #1fc4c2;">100%</span>
        </h2>
        <h4 class="top-slogan col-md-7 col-12">Федеральная сеть зарядных станций для электромобилей</h4>
        <h4 class="sub-slogan col-md-7 col-12">Производим и устанавливаем для Вас зарядные станции для электромобилей.
          Использование может быть личным или коммерческим.
        </h4>
        <div class="mt-5">
          <a class="second-type-btn" href="http://my.soh100.ru/">
            Начать зарядку
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
</script>

<style scoped>

.second-type-btn {
  padding: 15px 40px !important;
}

.top-slogan {
  color: white;
  font-size: 2.3rem;
  margin-top: 1.5rem;
}

.sub-slogan {
  color: white;
  margin-top: 1.5rem;
  font-size: 1.8rem;
}

.company-title {
  margin-top: 12rem;
  color: white;
  font-size: 5rem;
  font-weight: 600;
}

.mobile-icon {
  height: 30px;
  width: 30px;
}

.nav-menu {
  background-color: transparent !important;
}

.head-component {
  height: 100vh;
  background-image: url('../../public/images/headImage.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  max-height: 100vh;
  overflow-y: auto;

}

.logo-text {
  font-weight: 600;
  font-size: 1.5rem;
  color: #1fc4c2;
}

.login-nav-link {
  font-size: 1.5rem;
  text-decoration: none;
  border: 3px solid #073232;
  padding: 8px 20px;
  border-radius: 20px;
  color: rgb(29, 29, 29);
  background-color: white;
}

.login-nav-link:hover {
  background-color: #1fc4c2;
}

.menu-nav-link {
  font-size: 1.5rem;
  text-decoration: none;
  color: white;
}

.menu-nav-link:hover {
  color: aquamarine;
}

@media only screen and (max-width: 767px) {
  .company-title {
    margin-top: 50%;
    font-size: 2.2rem;
  }
  .top-slogan {
    font-size: 1.5rem;
    margin-top: 20px;
  }
  .sub-slogan {
    font-size: 1.5rem;
    margin-top: 20px;
  }
}

</style>
