<template>
    <div class="store-page mt-3">
      <div class="container-lg">
        <nav class="nav-menu navbar navbar-expand-lg navbar-light bg-light">
          <div class="container-fluid">
            <a class="navbar-brand" href="/">
              <div class="d-flex align-items-center">
                <img class="logo-style me-2" src="/images/logo.png" alt="..." />
                <div class="logo-text">SOH100</div>
              </div>
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span><img class="mobile-icon" src='/images/icons8-menu-50-gray.png' alt='...' /></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0 w-100 justify-content-end">
                <li class="nav-item me-md-4 mt-4 mt-md-0">
                  <router-link class="menu-nav-link" aria-current="page" to="/store">Продукты</router-link >
                </li>
                <li class="nav-item me-md-4 mt-2 mt-md-0">
                  <router-link class="menu-nav-link" aria-current="page" to="/partnership">Франшиза</router-link>
                </li>
                <li class="nav-item me-md-5 mt-2 mt-md-0">
                  <router-link class="menu-nav-link" aria-current="page" to="/contact">Контакты</router-link>
                </li>
                <li class="nav-item mt-3 mt-md-0">
                  <a class="login-nav-link" aria-current="page" href="http://my.soh100.ru/login">Войти</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </template>


<style scoped>

.menu-nav-link {
  color: rgb(46, 46, 46);
}

.menu-nav-link:hover{
  color: #1fc4c2;
}

.nav-menu {
  background-color: white !important;
}

.mobile-icon {
  height: 30px;
  width: 30px;
}

</style>