<template>
  <div class="prew-gallery">
    <div>
      <img class="active-img w-100" :src="'/images/stations/' + activeImage" alt="..." />
    </div>
    <div class="d-flex justify-content-center mt-4">
      <div class="me-3">
        <img class="sm-img w-100" :src="'/images/stations/' + imagesSrc[0]" alt="..." @mouseover="setActiveImg(imagesSrc[0])" />
      </div>
      <div class="me-3">
        <img class="sm-img w-100" :src="'/images/stations/' + imagesSrc[1]" alt="..." @mouseover="setActiveImg(imagesSrc[1])" />
      </div>
      <div>
        <img class="sm-img w-100" :src="'/images/stations/' + imagesSrc[2]" alt="..." @mouseover="setActiveImg(imagesSrc[2])" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PrewGalleryComponent',
  props: {
    imagesSrc: Array
  },
  data () {
    return {
      activeImage: null
    }
  },
  methods: {
    setActiveImg (img) {
      this.activeImage = img
    }
  },
  mounted () {
    this.activeImage = this.imagesSrc[0]
  }
}

</script>

<style scoped>

.sm-img {
  border-radius: 15px;
}

.active-img {
  border-radius: 15px;
}

</style>
