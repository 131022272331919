<template>
  <div class="partners-component">
    <h2 class="header">Наши партнеры</h2>
    <div class="d-flex justify-content-center">
      <PartnerOne image_src="images/partners/sfu.png" b_image_src="images/partners/sfu-modified.png" />
      <PartnerOne image_src="images/partners/sgk.png" b_image_src="images/partners/sgk-modified.png" />
      <PartnerOne image_src="images/partners/gaz.png" b_image_src="images/partners/gaz-modified.png" />
    </div>
  </div>
</template>

<script>
import PartnerOne from '@/components/PartnerOne.vue'

export default {
  name: 'PartnersComponent',
  components: {
    PartnerOne
  }
}
</script>

<style>
</style>
