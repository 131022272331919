import { createRouter, createWebHistory } from 'vue-router'

import HomePage from '../views/HomePage'
import StorePage from '../views/StorePage'
import StationPage from '../views/StationPage'
import PartnershipPage from '../views/PartnershipPage'
import PersonalDataPage from '../views/PersonalDataPage'
import ContactPage from '../views/ContactPage'
import ContactUsPage from '../views/ContactUsPage'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      component: HomePage
    },
    {
      path: '/store',
      component: StorePage
    },
    {
      path: '/station/:stationName',
      component: StationPage,
      props: route => ({
        stationName: route.params.stationName
      })
    },
    {
      path: '/partnership',
      component: PartnershipPage
    },
    {
      path: '/personalData',
      component: PersonalDataPage
    },
    {
      path: '/contact',
      component: ContactPage
    },
    {
      path: '/contact/form',
      component: ContactUsPage
    }

  ]
})

export default router
