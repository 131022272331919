<template>
  <div>
    <div>
      <HeadComponent />
    </div>
    <div
      ref="targetAboutUs"
      :class="{ 'default-animation': true, 'show': aboutUsIsVisible }"
      class="show-enter"
    >
      <AboutUsComponent />
    </div>
    <div
      ref="targetGallery"
      :class="{ 'default-animation': true, 'show': galleryIsVisible }"
      class="show-enter"
    >
      <GalleryComponent />
    </div>
    <div
      ref="targetPartners"
      :class="{ 'default-animation': true, 'show': partnersIsVisible }"
      class="show-enter"
    >
      <PartnersComponent />
    </div>
    <div>
      <FooterComponent />
    </div>
    <div class="get-call">
      <a href="tel:+74951478100" class="circle-link text-center">
        <img class="w-100" src="/images/in_8997757.png" alt="..." />
      </a>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useIntersectionObserver } from '@vueuse/core'

import HeadComponent from '@/components/HeadComponent.vue'
import AboutUsComponent from '@/components/AboutUsComponent.vue'
import GalleryComponent from '@/components/GalleryComponent.vue'
import FooterComponent from '@/components/FooterComponent.vue'
import PartnersComponent from '@/components/PartnersComponent.vue'

const targetAboutUs = ref(null)
const aboutUsIsVisible = ref(false)

const targetGallery = ref(null)
const galleryIsVisible = ref(false)

const targetPartners = ref(null)
const partnersIsVisible = ref(false)

let hasEnteredAboutUs = false
let hasEnteredGallery = false
let hasEnteredPartners= false

// eslint-disable-next-line
const { stop: stopAboutUs } = useIntersectionObserver(
  targetAboutUs,
  ([{ isIntersecting }]) => {
    if (isIntersecting && !hasEnteredAboutUs) {
      aboutUsIsVisible.value = true
      hasEnteredAboutUs = true
    }
  }
)

// eslint-disable-next-line
const { stop: stopGallery } = useIntersectionObserver(
  targetGallery,
  ([{ isIntersecting }]) => {
    if (isIntersecting && !hasEnteredGallery) {
      galleryIsVisible.value = true
      hasEnteredGallery = true
    }
  }
)

// eslint-disable-next-line
const { stop: stopPartners } = useIntersectionObserver(
  targetPartners,
  ([{ isIntersecting }]) => {
    if (isIntersecting && !hasEnteredPartners) {
      partnersIsVisible.value = true
      hasEnteredPartners = true
    }
  }
)

</script>

<style>

.show-enter-active {
  transition: opacity 1.2s ease, transform 1.2s ease;
}
.show-enter {
  opacity: 0;
  transform: translateX(100px);
}
.show-enter-to {
  opacity: 1;
  transform: translateY(0);
}

.default-animation {
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 1.2s ease, transform 1.2s ease;
}

.default-animation.show {
  opacity: 1;
  transform: translateY(0);
}

.get-call {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.circle-link {
  display: inline-block;
  width: 80px;
  height: 80px;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  background-color: white;
  color: #fff;
  text-decoration: none;
}

</style>
