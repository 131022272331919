<template>
  <div class="footer-component w-100">
    <div class="container">
      <div class="row d-flex">
        <div class="col-12 col-md-4">
          <div class="d-flex align-items-center justify-content-start">
            <img class="logo-style me-2" src="/images/logo.png" alt="..." />
            <div class="logo-text">SOH100</div>
          </div>
          <div class="d-flex justify-content-start">
            <a href="https://vk.com/soh100">
              <img class="social-link me-2" src="/images/wk_logo.png" alt="..." />
            </a>
            <a href="https://instagram.com/soh100ru?igshid=YmM0MjE2YWMzOA==">
              <img class="social-link" src="/images/instagram_logo.png" alt="..." />
            </a>
          </div>
          <div class="row mt-4 text-start">
            <div style="font-size: 1.3rem;">2020 © Все права защищены</div>
            <div style="font-size: 1.3rem;">ООО “СОХ100"</div>
            <div style="font-size: 1.3rem;">ОГРН 1212400007016</div>
          </div>
        </div>
        <div class="col-12 col-md-4 text-start mt-md-0 mt-4">
          <h3 class="footer-title">Продукты</h3>
          <div class="row">
            <router-link class="footer-item" to="/store">Каталог</router-link>
            <a class="footer-item" href="https://my.soh100.ru/map">К зарядным станциям</a>
            <router-link class="footer-item" to="/">Примеры установленных станций</router-link>
          </div>
          <h3 class="footer-title mt-4">Партнерство</h3>
          <div class="row">
            <router-link class="footer-item" to="/partnership">Франшиза</router-link>
            <router-link class="footer-item" to="/contact/form">Станции бесплатно</router-link>
          </div>
        </div>
        <div class="col-12 col-md-4 text-start mt-md-0 mt-4">
          <h3 class="footer-title">Контакты</h3>
          <div class="row">
            <a href="tel:+74951478100" class="footer-item mt-4">+7 (495) 147-81-00</a>
            <a href="email:info@soh100.ru" class="footer-item mt-3">Email: info@soh100.ru</a>
            <a href="#" class="footer-item mt-3">г. Красноярск ул. Марковского 7, офис 100</a>
            <router-link to="/contact/form" class="footer-item mt-3">Задать вопрос</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'FooterComponent'
}

</script>

<style scoped>

.footer-item {
  text-decoration: none;
  color: rgb(192, 192, 192);
  font-size: 1.3rem;
}

.footer-item:hover {
  color: #1fc4c2;
}

.footer-component {
  margin-top: 8rem;
  padding: 60px 0px;
  background-color: #403f3f;
  color: white;
}

.social-link {
  margin-top: 20px;
  width: 50px;
  height: 50px;
}

@media only screen and (max-width: 767px) {
  .footer-component {
    margin-top: 5rem;
    padding: 50px 0px;
  }
}

</style>
