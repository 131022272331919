import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import VueEasyLightbox from 'vue-easy-lightbox'
import '@/assets/styles/general.css';

const app = createApp(App)

app.use(router)
app.use(VueEasyLightbox)

app.config.globalProperties.elasticMailUsername = 'info@soh100.ru'
app.config.globalProperties.elasticMailKey = 'C9723BCB1FFDA3B62DBBC9CF2BEEA4C80C16'

app.mount('#app')
