<template>
  <div>
    <MainMenuComponent />
    <div class="partnership-page">
      <div class="container">
        <nav aria-label="breadcrumb" class="bread-cr">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><router-link class="bc-link" to="/">Главная</router-link></li>
            <li class="breadcrumb-item active" aria-current="page">Франшиза</li>
          </ol>
        </nav>
        <div
          ref="targetForYou"
          :class="{ 'default-animation': true, 'show': forYouIsVisible }"
          class="show-enter"
        >
          <h2 class="header-breadcrumps">Этот бизнес для тебя</h2>
          <div class="row d-flex justify-content-center align-items-center">
            <div class="col-12 col-md-6">
              <img class="w-100 rounded-4" src="/images/autopark.png" alt="..." />
            </div>
            <div class="col-12 col-md-6">
              <ul class="text-start text-block mt-4 mt-md-0">
                <li>Решение для всех, кто хочет прибыльный и честный бизнес без лишних заморочек.</li>
                <li>Поставил зарядки, даешь людям возможность зарядиться, заботишься о экологии и окружающей среде.</li>
                <li>Получаешь доход с минимальной личной вовлеченностью.</li>
              </ul>
            </div>
          </div>
        </div>
        <div
          ref="targetInfo"
          :class="{ 'default-animation': true, 'show': infoIsVisible }"
          class="show-enter"
        >
          <h2 class="header">Нужно конкретнее? Если вы:</h2>
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  Почему<span style="color: #1fc4c2;">&nbsp;SOH100</span>
                </button>
              </h2>
              <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <h2 class="header">Почему<span style="color: #1fc4c2; font-weight: bold;">&nbsp;SOH100</span></h2>
                  <div class="row justify-content-center mb-5">
                    <div class="col-md-7 col-12 d-flex align-items-center justify-content-start">
                      <img class="me-3" src="/images/icons8-1-100.png" alt="..." />
                      <p class="text-block">Без паушального взноса и скрытых платежей</p>
                    </div>
                    <div class="col-md-7 col-12 d-flex align-items-center justify-content-start">
                      <img class="me-3" src="/images/icons8-2-100.png" alt="..." />
                      <p class="text-block">Вы платите только за оборудование</p>
                    </div>
                    <div class="col-md-7 col-12 d-flex align-items-center justify-content-start">
                      <img class="me-3" src="/images/icons8-3-100.png" alt="..." />
                      <p class="text-block">Техническая поддержка и кол-центр 24/7</p>
                    </div>
                    <div class="col-md-7 col-12 d-flex align-items-center justify-content-start">
                      <img class="me-3" src="/images/icons8-4-100.png" alt="..." />
                      <p class="text-block">Доля франчайзи 80%, роялти 20%</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  Калькулятор франшизы
                </button>
              </h2>
              <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <h2 class="header" style="margin-bottom: 2rem;">Калькулятор франшизы</h2>
                  <div class="row justify-content-center">
                    <p class="text-block col-md-6 col-12  text-center">
                      Наша компания предлагает несколько вариантов франшизы. Также, готовы проработать индивидуальные условия
                    </p>
                  </div>
                  <div class="overflow-x-auto my-5">
                    <img src="/images/table.png" alt="..." />
                  </div>
                  <div class="d-flex justify-content-center align-items-center mb-5">
                    <a href="https://disk.yandex.ru/i/4LIaupmtH2aj5A" class="text-block">Получите подробную информацию в презентации:</a>
                    <img src="/images/icons8-power-point-96.png" style="width: 50px; height: auto;" alt="..." />
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  Как стать партнером
                </button>
              </h2>
              <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <div class="row justify-content-center mt-5">
                    <div class="col-md-10 col-12 text-start">
                      <h3 style="font-weight: bold;">Как стать партнером:</h3>
                      <ul class="text-block mt-4">
                        <li>Получить консультацию по запуску франшизы.</li>
                        <li class="mt-3">Заключить договор.</li>
                        <li class="mt-3">Оплатить оборудование.</li>
                        <li class="mt-3">Пройти обучение в <span style="color: #1fc4c2;">&nbsp;SOH100</span> и получить план развития.</li>
                        <li class="mt-3">Получить оборудование.</li>
                        <li class="mt-3">Расставить станции в городе и получить первый доход.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFour">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                  Что входит в франшизу
                </button>
              </h2>
              <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <div class="row justify-content-center mt-5">
                    <div class="col-md-10 col-12 text-start">
                      <h3 style="font-weight: bold;">Что входит в франшизу:</h3>
                      <ul class="text-block mt-4">
                        <li>Руководство по запуску и операционной работе.</li>
                        <li class="mt-3">Техническая поддержка и колл-центр 24/7.</li>
                        <li class="mt-3">Федеральная маркетинговая программа.</li>
                        <li class="mt-3">Приложение для партнера и сервисные программы.</li>
                        <li class="mt-3">Помощь в размещении.</li>
                        <li class="mt-3">Телеграмм канал для партнеров.</li>
                        <li class="mt-3">Персональный аккаунт-менеджер: поддержка в бизнес вопросах.</li>
                        <li class="mt-3">ДОПОЛНИТЕЛЬНО: Можем запустить франшизу в вашем городе силами нашей команды.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFive">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                  Программное обеспечение
                </button>
              </h2>
              <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <div class="row justify-content-center mt-5">
                    <div class="col-md-10 col-12 text-start">
                      <h3 style="font-weight: bold;">Все партнеры-франчайзи получают доступ в личный кабинет, где можно:</h3>
                      <ul class="text-block mt-4">
                        <li>Руководство по запуску и операционной работе.</li>
                        <li class="mt-3">Производить постоянный мониторинг состояния сети.</li>
                        <li class="mt-3">Получать данные о транзакциях во всех станциях сети на своей территории.</li>
                        <li class="mt-3">Получать подробные аналитические данные и формировать отчеты.</li>
                        <li class="mt-3">Управлять зарядными станциями через административную часть.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingSix">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                  Чего мы ждем от партнера
                </button>
              </h2>
              <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <div class="row justify-content-center mt-5">
                    <div class="col-md-10 col-12 text-start">
                      <h3 style="font-weight: bold;">Инвестиции:</h3>
                      <ul class="text-block mt-4">
                        <li>Больше станций - больше ядро аудитории - больше доход.</li>
                      </ul>
                    </div>
                  </div>
                  <div class="row justify-content-center mt-5">
                    <div class="col-md-10 col-12 text-start">
                      <h3 style="font-weight: bold;">Коммуникации:</h3>
                      <ul class="text-block mt-4">
                        <li>Договориться о размещении оборудования <span style="color: #1fc4c2;">&nbsp;SOH100</span> в лучших локациях в городе и они сформируют ядро аудитории.</li>
                      </ul>
                    </div>
                  </div>
                  <div class="row justify-content-center mt-5">
                    <div class="col-md-10 col-12 text-start">
                      <h3 style="font-weight: bold;">Ресурсы:</h3>
                      <ul class="text-block mt-4">
                        <li>Возможность вести операционное управление бизнесом в городе. Бизнес можно развивать самостоятельно, а можно нанять сотрудников.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingSeven">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                  Что нужно для открытия зарядной точки
                </button>
              </h2>
              <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <div class="row justify-content-center mt-5">
                    <div class="col-md-10 col-12 text-start">
                      <h3 style="font-weight: bold;">Требования к объекту:</h3>
                      <ul class="text-block mt-4">
                        <li>Парковочное место площадью 6 м².</li>
                        <li>Резервная мощность на объекте — от 3.5 кВт.</li>
                        <li>Дорожная разметка: знак «Зарядка для электромобилей».</li>
                        <li>Перед установкой станции подготовить бетонную площадку с закладной.</li>
                        <li>Монтаж осуществляется профессиональной командой электриков.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingEight">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                  Локация зарядки
                </button>
              </h2>
              <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <div class="row justify-content-center mt-5">
                    <div class="col-md-10 col-12 text-start">
                      <h3 style="font-weight: bold;">ПОСТАВЬ зарядку ГДЕ УГОДНО:</h3>
                      <ul class="text-block mt-4">
                        <li>Найдите свободную станцию на карте, доберитесь к ней на электромобиле и найдите ее на местности (обычно, станции размещаются на фасаде на уровне глаз и представляют собой ярко синий ящик.)</li>
                        <li>Поднимите вверх крышку розетки и вставьте в розетку зарядное устройство (евровилку), а с другой стороны подключите его к электромобилю.</li>
                        <li>На сайте выберите станцию, около которой Вы стоите и нажмите "Начать зарядку".</li>
                        <li>Индикатор на станции станет зеленым, а на экране телефона отобразится время заряда.</li>
                        <li>Если вы хотите остановить зарядку, нажмите "Остановить зарядку" в телефоне. Таймер остановится, а статистика заряда будет в разделе "История".</li>
                        <li>Вытащите вилку и закройте крышку розетки (она опустится сама).</li>
                      </ul>
                    </div>
                    <div class="row justify-content-center">
                      <a href="http://my.soh100.ru/map" class="second-type-btn my-5 col-10 col-md-3">К зарядным станциям</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          ref="targetSlogan"
          :class="{ 'default-animation': true, 'show': sloganIsVisible }"
          class="show-enter"
        >
          <div class="slogan-bottom row justify-content-center py-5">
            <h1 class="col-12 col-md-10">ВСЕ, ЧТО ТЕБЕ НУЖНО НА СТАРТЕ — ЭТО ВЫБРАТЬ ЛОКАЦИЮ В СВОЕМ ГОРОДЕ</h1>
          </div>
        </div>
      </div>
    </div>
    <FooterComponent />
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useIntersectionObserver } from '@vueuse/core'

import MainMenuComponent from '@/components/MainMenuComponent.vue'
import FooterComponent from '@/components/FooterComponent.vue'

const targetForYou = ref(null)
const forYouIsVisible = ref(false)

const targetInfo = ref(null)
const infoIsVisible = ref(false)

const targetSlogan = ref(null)
const sloganIsVisible = ref(false)

let hasEnteredForYou = false
let hasEnteredInfo = false
let hasEnteredSlogan = false

// eslint-disable-next-line
const { stop: stopForYou } = useIntersectionObserver(
  targetForYou,
  ([{ isIntersecting }]) => {
    if (isIntersecting && !hasEnteredForYou) {
      forYouIsVisible.value = true
      hasEnteredForYou = true
    }
  }
)

// eslint-disable-next-line
const { stop: stopInfo } = useIntersectionObserver(
  targetInfo,
  ([{ isIntersecting }]) => {
    if (isIntersecting && !hasEnteredInfo) {
      infoIsVisible.value = true
      hasEnteredInfo = true
    }
  }
)

// eslint-disable-next-line
const { stop: stopSlogan } = useIntersectionObserver(
  targetSlogan,
  ([{ isIntersecting }]) => {
    if (isIntersecting && !hasEnteredSlogan) {
      sloganIsVisible.value = true
      hasEnteredSlogan = true
    }
  }
)

</script>

<style scoped>

.accordion-button {
  padding: 25px 15px;
  font-size: 1.4rem !important;
}

.slogan-bottom {
  margin-top: 8rem;
  border-top: 2px solid #1fc4c2;
  border-bottom: 2px solid #1fc4c2;
}

@media (max-width: 768px) {
  .slogan-bottom {
    margin-top: 5rem;
  }
}

</style>
