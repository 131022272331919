<template>
  <div>
    <MainMenuComponent />
    <div class="container">
      <nav aria-label="breadcrumb" class="bread-cr">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link class="bc-link" to="/">Главная</router-link></li>
          <li class="breadcrumb-item"><router-link class="bc-link" to="/store">Продукты</router-link></li>
          <li class="breadcrumb-item active" aria-current="page">{{ stationName }}</li>
        </ol>
      </nav>
      <div class="station-component">
        <div v-if="currentStation">
          <div class="row d-flex">
            <div class="col-12 col-md-4 justify-content-center pe-md-5 pe-3">
              <PrewGalleryComponent :imagesSrc="currentStation.images" />
            </div>
            <div class="col-12 col-md-8 justify-content-center text-start mt-4 mt-md-0">
              <h3 class="st-title w-100 text-start">{{ currentStation.title }}</h3>
              <h4 class="mt-4 item-p">Цена: <span style="font-weight: bold;">{{ currentStation.price }}</span></h4>
              <h4 class="mt-3 item-p">Монтаж: <span style="font-weight: bold;">{{ currentStation.installation }}</span></h4>
              <h4 class="mt-3 item-p">Количество портов: <span style="font-weight: bold;">{{ currentStation.coutSocket }}</span></h4>
              <h4 class="mt-3 item-p">Порт: <span style="font-weight: bold;">{{ currentStation.socket }}</span></h4>
              <h4 class="mt-3 item-p">Мощность: <span style="font-weight: bold;">{{ currentStation.power }}</span></h4>
              <h4 class="mt-3 item-p">Удалённое управление: <span style="font-weight: bold;">{{ currentStation.remoteControl }}</span></h4>
              <h4 class="mt-3 item-p">{{ currentStation.desc }}</h4>
              <div class="w-100 text-end mt-5">
                <router-link to="/contact/form" class="second-type-btn me-4">
                  Заказать
                </router-link>
                <router-link to="/contact/form" style="font-size: 1.4rem; color: #1fc4c2;">
                  Уточнить детали
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <p>Station not found</p>
        </div>
      </div>
    </div>
    <FooterComponent />
  </div>
  
</template>

<script>
import MainMenuComponent from '@/components/MainMenuComponent.vue'
import FooterComponent from '@/components/FooterComponent.vue'
import PrewGalleryComponent from '@/components/PrewGalleryComponent.vue'

export default {
  components: {
    MainMenuComponent,
    FooterComponent,
    PrewGalleryComponent
  },
  props: {
    stationName: String
  },
  data () {
    return {
      stationsItems: [
        {
          href: '#soh100Lite',
          imageSrc: 'images/stations/three_kw_2.png',
          title: 'SOH100 Light',
          power: '3.5 кВт',
          desc: 'Универсальная компактная зарядная станция средней мощности обладает способностью заряжать одновременно только один электромобиль. Она подходит как для индивидуального использования, так и для коммерческого предоставления услуг зарядки владельцам электромобилей. Эта зарядная станция совместима с автомобилями различных производителей',
          price: '₽60 000',
          installation: 'стена/стойка',
          coutSocket: '1',
          socket: 'Euro розетка',
          remoteControl: 'опционально',
          images: ['three_kw.png', 'three_kw_2.png', 'three_kw_2.png']
        },
        {
          href: '#soh100Middle',
          imageSrc: 'images/stations/ten_kw.png',
          title: 'SOH100 Middle',
          power: '10 кВт',
          desc: 'Универсальная компактная зарядная станция средней мощности обладает способностью заряжать одновременно только один электромобиль. Она подходит как для индивидуального использования, так и для коммерческого предоставления услуг зарядки владельцам электромобилей. Эта зарядная станция совместима с автомобилями различных производителей',
          price: '₽100 000',
          installation: 'стена/стойка',
          coutSocket: '2',
          socket: 'Type 1 / Type 2 / GB/T',
          remoteControl: 'опционально',
          images: ['ten_kw.png', 'ten_kw_2.png', 'ten_kw_3.png']
        },
        {
          href: '#soh100MiddlePro',
          imageSrc: 'images/stations/two_kw.png',
          title: 'SOH100 Middle PRO',
          power: '22 кВт',
          desc: 'Универсальная компактная зарядная станция средней мощности обладает способностью заряжать одновременно только один электромобиль. Она подходит как для индивидуального использования, так и для коммерческого предоставления услуг зарядки владельцам электромобилей. Эта зарядная станция совместима с автомобилями различных производителей',
          price: '₽150 000',
          installation: 'стена/стойка',
          coutSocket: '2',
          socket: 'Type 1 / Type 2 / GB/T',
          remoteControl: 'опционально',
          images: ['two_kw.png', 'two_kw_2.png', 'two_kw_3.png']
        },
        {
          href: '#soh100SolwX',
          imageSrc: 'images/stations/ss.png',
          title: 'SOH100 slow X',
          power: 'Н/Д',
          desc: 'В разработке',
          price: 'Н/Д',
          installation: 'Н/Д',
          coutSocket: 'Н/Д',
          socket: 'Н/Д',
          remoteControl: 'Н/Д',
          images: ['k2.png', 'k2_1.png', 'k2_2.png']
        },
        {
          href: '#soh100FastX',
          imageSrc: 'images/stations/k2.png',
          title: 'SOH100 fast X',
          power: 'Н/Д',
          desc: 'В разработке',
          price: 'Н/Д',
          installation: 'Н/Д',
          coutSocket: 'Н/Д',
          socket: 'Н/Д',
          remoteControl: 'Н/Д',
          images: ['ss.png', 'ss_1.png', 'ss_2.png']
        }
      ],
      currentStation: null
    }
  },
  mounted () {
    this.currentStation = this.stationsItems.find(station => station.title === this.stationName)
  }
}
</script>

<style scoped>

.bc-link {
  color: #1fc4c2;
}

.bread-cr {
  font-size: 1.2rem;
  margin-top: 5rem;
}

.item-p {
  font-size: 1.3rem;
}

.contact-link {
  font-size: 1.5rem;
  text-decoration: none;
  border: 3px solid #073232;
  padding: 8px 20px;
  border-radius: 20px;
  color: rgb(29, 29, 29);
  background-color: white;
}

.contact-link:hover {
  background-color: #1fc4c2;
}

.st-title {
  font-size: 2.8rem;
}

.station-component {
  border: 1px solid rgb(224, 224, 224);
  padding: 40px 30px;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .station-component {
    margin-top: 3rem;
    padding: 20px 10px;
  }
}

</style>
