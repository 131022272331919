<template>
  <div>
    <MainMenuComponent />
    <div class="personal-data-page">
      <h3 class="terms-title">Согласие на обработку персональных данных</h3>
      <div class="container">
        <div class="text-block text-start">
          <p>1. Когда Вы оставляете заявку в любой форме обратной связи на сайте http://soh100.ru/, http://my.soh100.ru/, Вы даете согласие ООО "СОХ100", (далее — «Оператор») на:<br>
            — обработку Ваших персональных данных;<br>
            — получение от Вас персональных данных;<br>
            — передачу Ваших персональных данных работникам Оператора, которые обрабатывают такие заявки.
          </p>
          <p>
            2. Согласие Вы даете для следующей цели: ответ по телефону (в том числе через мессенджер) или по электронной почте на заявку, которую Вы оставили в форме обратной связи.
          </p>
          <p>
            3. Вы подтверждаете, что, принимая решение, действуете свободно, своей волей и в своем интересе.
          </p>
          <p>
            4. Согласие дается на обработку персональных данных, как без использования средств автоматизации, так и с их использованием.
          </p>
          <p>
            5. Согласие распространяется на персональные данные, не являющиеся специальными или биометрическими:
            — для сайта: Имя, Контактный номер телефона, Адрес электронной почты, Город;
            — для приложения: Контактные данные (Псевдоним пользователя, Адрес электронной почты, Номер телефона), Геопозиция (Точная и Приблизительная Геолокации), Пользовательский контент (например, Фото профиля, Отзывы, Сообщения с технической поддержкой сервиса и т. д.), История просмотров, История поиска, Идентификаторы (ID пользователя, ID устройства), Покупки, Данные об использовании (Взаимодействие с продуктом, Рекламные данные, Другое), Диагностика (Данные о сбоях).
          </p>
          <p>
            6. Действия, на которые Вы предоставляете свое согласие: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, распространение (в том числе передача третьим лицам для аналогичных целей), блокирование, уничтожение.
          </p>
          <p>
            7. Персональные данные не являются общедоступными, Оператор сохраняет конфиденциальность и целостность Ваших персональных данных, соблюдая при этом положения ФЗ №152 «О персональных данных»
          </p>
          <p>8. Согласие действует до достижения цели обработки персональных данных или до утраты необходимости в достижении такой цели, или до отзыва согласия.</p>
          <p>9. Согласие может быть в любой момент отозвано Вами путём письменного обращения к Оператору.</p>
          <p>
            10. Обработка персональных данных может быть продолжена при наличии оснований, указанных в пунктах 2-11 части 1 статьи 6, части 2 статьи 10 и части 2 статьи 11 ФЗ №152 «О персональных данных».
          </p>
          <p>11. Реквизиты Оператора:</p>
          <p>
            ООО "СОХ100"<br>
            Юридический адрес: 660030 Красноярск, ул. Марковского 7<br>
            ИНН: 2463123500<br>
            Адрес электронной почты: info@soh100.ru<br>
            Номер телефона: +7 (495) 147-81-00
            </p>
        </div>
      </div>
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import MainMenuComponent from '@/components/MainMenuComponent.vue'
import FooterComponent from '@/components/FooterComponent.vue'

export default {
  name: 'PersonalData',
  components: {
    MainMenuComponent,
    FooterComponent
  }
}

</script>

<style scoped>

.terms-title {

  margin-top: 8rem;
  margin-bottom: 5rem;
}

@media (max-width: 768px) {
  .terms-title {
    font-size: 2rem;
    margin-top: 4rem;
    margin-bottom: 3rem;
  }
}

</style>
