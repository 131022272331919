<template>
  <div class="gallery">
    <h2 class="header">Установленные станции</h2>
    <div class="container">
      <div class="row gallery-cards justify-content-center">
        <img
          class="img-item"
          v-for="(img, index) in imgsRef"
          :key="index"
          :src="img"
          @click="openLightbox(index)"
        />
      </div>
      <vue-easy-lightbox
        :visible="visibleRef"
        :imgs="imgsRef"
        :index="indexRef"
        @hide="onHide"
      ></vue-easy-lightbox>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'GalleryComponent',
  setup() {
    const visibleRef = ref(false)
    const indexRef = ref(0)
    const imgsRef = ref([
      'images/gallery/2.png',
      'images/gallery/1.png',
      'images/gallery/5.jpeg',
      'images/gallery/3.png',
      'images/gallery/6.jpeg',
      'images/gallery/4.jpeg'
    ])
    const onShow = () => {
      visibleRef.value = true
    }
    const openLightbox = (index) => {
      indexRef.value = index
      onShow()
    }
    const onHide = () => {
      visibleRef.value = false
    }
    return {
      visibleRef,
      indexRef,
      imgsRef,
      openLightbox,
      onHide
    }
  }
}

</script>

<style scoped>

.img-item {
  height: 300px;
  width: auto;
  border: 3px solid #2c3e50;
  border-radius: 15px;
  padding: 0px;
  margin: 15px;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.img-item:hover {
  transform: scale(1.02);
}

</style>
