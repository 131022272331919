<template>
  <div>
    <MainMenuComponent />
    <div class="contact-page">
      <div class="container">
        <div class="contact">
          <h2 class="my-4">Задайте вопрос — дадим бесплатную консультацию</h2>
          <div class="text-start text-block">
            <p>+7(495)147-81-00</p>
            <p>ООО "СОХ100"</p>
            <p>Юридический адрес: 660030 Красноярск, ул. Марковского 7</p>
            <p>ИНН: 2463123500</p>
            <p>Адрес электронной почты: info@soh100.ru</p>
          </div>
        </div>
        <div class="mt-5">
          <router-link class="login-nav-link" to="/contact/form">Есть вопрос</router-link>
        </div>
      </div>
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import MainMenuComponent from '@/components/MainMenuComponent.vue'
import FooterComponent from '@/components/FooterComponent.vue'

export default {
  name: 'ContactPage',
  components: {
    MainMenuComponent,
    FooterComponent
  }
}

</script>

<style scoped>

.contact-page {
  margin-top: 8rem;
}

.contact {
  padding: 20px 40px;
  border: 1px solid rgb(221, 221, 221);
  border-radius: 15px;
}

@media (max-width: 768px) {
  .contact-page {
    margin-top: 3.5rem;
  }
  .contact {
    padding: 20px 15px;
  }
}

</style>
