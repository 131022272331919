<template>
  <div class="about-us-component">
    <div class="container-sm">
      <h2 class="header">О компании <span class="blue-marker">SOH100</span></h2>
      <div class="row d-flex align-items-center">
        <div class="col-12 col-md-6 text-start">
          <p class="text-block">
            <span style="font-weight: bold;">SOH100</span> — производитель зарядных станций для электротранспорта и программного обеспечения для работы зарядной инфраструктуры.
          </p>
          <p class="text-block">
            Мы предлагаем <span style="font-weight: bold;">надежное</span> оборудование и <span style="font-weight: bold;">удобный</span> сервис зарядки электротранспорта, с полным сопровождением для создания зарядной инфраструктуры и бизнеса.
          </p>
          <p class="text-block">
            Компания <span style="font-weight: bold;">SOH100</span> предлагает станции для зарядки электротранспорта и программный комплекс услуг по комплексному управлению как единичным оборудованием, так и сетью электрических зарядных станций.
          </p>
          <p class="text-block">
            В рамках проекта мы предоставляем:
          </p>
          <ul>
            <li>
              <p class="text-block">Удаленное управление и мониторинг станций</p>
            </li>
            <li>
              <p class="text-block">Поддержку клиентов 24/7</p>
            </li>
            <li>
              <p class="text-block">Монетизацию с различными алгоритмами оплаты</p>
            </li>
            <li>
              <p class="text-block">Аналитику через личный кабинет</p>
            </li>
            <li>
              <p class="text-block">Сервисное обслуживание</p>
            </li>
          </ul>
        </div>
        <div class="col-12 col-md-6">
          <img class="w-100" src="images/desing_img.png" alt="..." />
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>

export default {
  name: 'AboutUsComponent'
}

</script>

<style scoped>

@import "../../src/assets/styles/general.css"

</style>
