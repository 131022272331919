<template>
  <div class="store-page">
    <div class="container-lg">
      <MainMenuComponent />
    </div>
    <div class="container">
      <nav aria-label="breadcrumb" class="bread-cr">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link class="bc-link" to="/">Главная</router-link></li>
          <li class="breadcrumb-item active" aria-current="page">Продукты</li>
        </ol>
      </nav>
      <h3 class="store-sub-title w-100 text-start">Станции переменного тока</h3>
      <div class="mt-5">
        <transition-group
          name="list-complete"
          tag="p"
        >
          <div class="row justify-content-start">
            <div
              v-for="(item, index) in themeItems"
              :key="item.text"
              :style="{ transitionDelay: `${index * 0.1}s` }"
              class="list-complete-item col-md-3 col-10 offset-1 offset-md-0"
              :class="{ 'fade-enter-active': show }"
            >
              <div>
                <router-link :to="'/station/' + item.title" class="card mt-2">
                  <img :src="item.imageSrc" class="card-img-top" alt="...">
                  <div class="card-body text-start">
                    <h5 class="card-title">{{ item.title }}</h5>
                    <p class="card-text">{{ item.desc }}</p>
                    <p class="card-text">{{ item.power }}</p>
                    <p class="card-text">{{ item.price }}</p>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </transition-group>
      </div>
      <div class="mt-5 pt-4">
        <h3 class="store-sub-title w-100 text-start">Станции в разработке</h3>
        <transition-group
            name="list-complete"
            tag="p"
          >
            <div class="row justify-content-start mt-5">
              <div
                v-for="(item, index) in themeItemsDev"
                :key="item.text"
                :style="{ transitionDelay: `${index * 0.1}s` }"
                class="list-complete-item col-md-3 col-10 offset-1 offset-md-0"
                :class="{ 'fade-enter-active': show }"
              >
                <div>
                  <router-link :to="'/station/' + item.title" class="card mt-2">
                    <img :src="item.imageSrc" class="card-img-top" alt="...">
                    <div class="card-body text-start">
                      <h5 class="card-title">{{ item.title }}</h5>
                      <p class="card-text">{{ item.power }}</p>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </transition-group>
      </div>
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import MainMenuComponent from '@/components/MainMenuComponent.vue'
import FooterComponent from '@/components/FooterComponent.vue'

export default {
  components: {
    MainMenuComponent,
    FooterComponent
  },
  data () {
    return {
      themeItems: [
        {
          id: 'soh100Lite',
          href: '#soh100Lite',
          imageSrc: 'images/stations/three_kw_2.png',
          title: 'SOH100 Light',
          power: 'Мощность: 3.5 кВт',
          desc: 'Младшая версия базовой станции. Очень практичная и не большая по размеру.',
          price: 'Цена: ₽60 000'
        },
        {
          id: 'soh100Middle',
          href: '#soh100Middle',
          imageSrc: 'images/stations/ten_kw.png',
          title: 'SOH100 Middle',
          power: 'Мощность: 10 кВт',
          desc: 'Средняя версия зарадного устройства. Обладает отличной мощностью на один электромобиль.',
          price: 'Цена: ₽100 000'
        },
        {
          id: 'soh100MiddlePro',
          href: '#soh100MiddlePro',
          imageSrc: 'images/stations/two_kw.png',
          title: 'SOH100 Middle PRO',
          power: 'Мощность: 22 кВт',
          desc: 'Большая зарядная станция для одновременной зарядки двух электромобилей.',
          price: 'Цена: ₽150 000'
        }
      ],

      themeItemsDev: [
        {
          id: 'soh100SolwX',
          href: '#soh100SolwX',
          imageSrc: 'images/stations/ss.png',
          title: 'SOH100 slow X',
          power: 'Мощность: Н/Д'
        },
        {
          id: 'soh100FastX',
          href: '#soh100FastX',
          imageSrc: 'images/stations/k2.png',
          title: 'SOH100 fast X',
          power: 'Мощность: Н/Д'
        }
      ],

      show: false
    }
  },
  mounted () {
    setTimeout(() => {
      this.show = true
    }, 300)
  }
}
</script>

<style scoped>

.card {
  text-decoration: none;
  transition: transform 0.5s ease;
}

.card:hover {
  transform: scale(0.98);
}

.list-complete-item {
  transition: opacity 0.5s, transform 1s;
  display: inline-block;
  opacity: 0;
  transform: translateY(50px);
}
.fade-enter-active {
  transition: opacity 0.5s, transform 1s;
  opacity: 1;
  transform: translateY(0);
}

.store-sub-title {
  font-size: 2rem;
  color: rgb(48, 48, 48);
  margin-top: 2rem;
}

@media (max-width: 768px) {
  .store-sub-title {
    font-size: 1.5rem;
    margin-top: 3rem;
  }
}
</style>
